<template>
  <div>
    <discount-scheme-aside
      v-if="aside === 'scheme'"
      v-model="aside"
      :resource="scheme"
      @changed="loadDiscountSchemes(); aside = false"
      @aside="handleAside"
    ></discount-scheme-aside>

    <v-card>
      <!-- search -->
      <v-card-text class="d-flex align-center flex-wrap pb-5">
        <div class="d-flex align-center">
          <v-text-field
            v-model="searchQuery"
            single-line
            dense
            outlined
            hide-details
            placeholder="Search DiscountScheme Code"
            class="invoice-list-search me-3"
          ></v-text-field>
        </div>

        <v-spacer></v-spacer>

        <v-btn
          color="success"
          class="mr-2"
          @click="aside = 'scheme'; resource = null;"
        >
          Create +
        </v-btn>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedTableData"
        :headers="tableColumns"
        :items="listTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.name`]="{item}">
          <span>{{ t(item.name) }}</span>
        </template>

        <template #[`item.channels`]="{item}">
          <v-chip
            v-for="channel in item.channels"
            :key="channel.id"
          >
            {{ t(channel.name) }}
          </v-chip>
        </template>

        <!-- date -->
        <template #[`item.created_at`]="{item}">
          <span class="text-no-wrap">{{ item.created_at | dateTime }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="setItem(item, 'scheme')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="confirmTrashDiscountScheme(item).then(loadDiscountSchemes)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import { dateTime, price } from '@core/utils/filter'
import {
mdiDotsVertical, mdiPencil, mdiPlus, mdiTrashCan
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import DiscountSchemeAside from '../discount-scheme-resource/DiscountSchemeAside.vue'

// composition function
import useDiscountSchemeList from './useDiscountSchemeList'

export default {
  components: { DiscountSchemeAside },

  filters: { price, dateTime },

  setup() {
    const {
      listTable, searchQuery, tableColumns, options, statusFilter, totalListTable, loading, selectedTableData, loadDiscountSchemes, confirmTrashDiscountScheme,
    } = useDiscountSchemeList()

    const aside = ref(false)
    const scheme = ref(null)

    const setItem = (item, action = 'scheme') => {
      scheme.value = item
      aside.value = action
    }

    const handleAside = bol => {
      if (!bol) {
        aside.value = false
        scheme.value = false
        loadDiscountSchemes()
      }
    }

    return {
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      totalListTable,
      listTable,
      loading,
      selectedTableData,

      t,

      aside,
      scheme,
      setItem,
      handleAside,
      loadDiscountSchemes,
      confirmTrashDiscountScheme,

      icons: {
        mdiDotsVertical,
        mdiPencil,
        mdiTrashCan,
        mdiPlus,
      },
    }
  },
}
</script>

  <style lang="scss" scoped>
  .invoice-list-search {
    max-width: 10.625rem;
  }
  .invoice-list-status {
    max-width: 11.3rem;
  }
  </style>
