<template>
  <v-form ref="formElem">
    <translation-locales />

    <v-card>
      <v-card-text>
        <v-row class="mt-5">
          <v-col :cols="cols">
            <translatable-input
              v-model="form.name"
              label="Display Name"
            >
            </translatable-input>
          </v-col>

          <v-col :cols="cols">
            <v-switch
              v-model="form.active"
              color="primary"
              :label="form.active ? 'Active' : 'Inactive'"
              :disabled="disabledFields.indexOf('active') >= 0"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <slot name="channels"></slot>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslationLocales, TranslatableInput },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      name: props.resource ? props.resource.name : useTranslatable(),
      active: props.resource ? props.resource.active : 1,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      const data = { ...form.value }

      if (formElem.value.validate()) emit('submit', data)
    }

    return {
      form,
      formElem,
      validate,
      required,
    }
  },
}
</script>
